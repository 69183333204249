@import url("/node_modules/@qonsoll/react-design/dist/styles/vars/index.css");
@import url("./vars/diff_dark.css");
@import url("./vars/diff_light.css");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

#root {
  height: inherit;
  display: flex;
  flex-direction: column;
}

/* Fix for AntD Input component: prevent double background while autocomplete works ——— Start*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill::first-line,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  transition: background-color 5000s ease-in-out 0s;
  color: var(--ql-typography-text-color-primary);
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent !important;
}

/* Fix for AntD Input component: prevent double background while autocomplete works ——— End */

/* DIVIDER */
.ant-divider-horizontal.ant-divider-with-text {
  font-size: 16px;
  margin: 0;
}

/* TIMELINE */
.ant-timeline-item-content {
  top: -15px;
  margin: 0 0 0 18px;
}

.ant-carousel {
  width: 100%;
}

/* CARD */
.ant-card-body {
  padding: 20px 24px;
}
.ant-card-small > .ant-card-body {
  padding: 12px 24px;
}

/* BADGE */
.ant-badge-count {
  box-shadow: none;
}
.ant-badge-multiple-words {
  padding: 0 4px;
}
.ant-scroll-number-only {
  height: inherit;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: inherit;
}

/* AVATAR */

.ant-avatar-group .ant-avatar.ant-avatar-lg:not(:last-child) {
  clip-path: path(
    "M20,0 C24.5026548,0 28.6577845,1.48793206 32.0005148,3.99892198 C27.1424245,7.64696983 24,13.4565086 24,20 C24,26.5434914 27.1424245,32.3530302 32.0004901,36.0018329 C28.6577845,38.5120679 24.5026548,40 20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 Z"
  );
}
.ant-avatar-lg {
  line-height: 36px;
}
.ant-avatar-string {
  color: #111;
}

/* BUTTON */
/* .ant-btn:hover,
.ant-btn:focus {
  color: #111;
  background: rgba(17, 17, 17, 0.12);
  border-color: transparent;
} */
.ant-btn:disabled,
.ant-btn[disabled] {
  border-color: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: var(--orange-3);
  background: transparent;
}
/* TAG */
.ant-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
}
.ant-tag:last-child {
  margin-right: 0;
}

/* CAROUSEL
 .ant-carousel {
     border-radius: 16px 16px 0 0;
     overflow: hidden;
 }
*/
.ant-card-cover img {
  border-radius: 0;
}
.ant-carousel .slick-initialized .slick-slide {
  opacity: 0.6;
  transition: all 0.3s;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  opacity: 1;
  transition: all 0.3s;
}
.ant-carousel .slick-dots-bottom {
  bottom: 0;
}

/* UPLOAD */
.ant-upload.ant-upload-select {
  display: block;
}

.ant-menu-inline-collapsed {
  width: auto;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}
.ant-tooltip-inner {
  border-radius: var(--border-radius-base);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}
