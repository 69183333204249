:root.light {/* Accent —————————————————————————————— Start */
    --ql-color-accent1: rgb(246, 112, 77);
  
    /* Transparent shades */
    --ql-color-accent1-t-lighten1: rgb(246, 112, 77, var(--ql-color-blending-lighten1));
    --ql-color-accent1-t-lighten2: rgb(246, 112, 77, var(--ql-color-blending-lighten2));
    --ql-color-accent1-t-lighten3: rgb(246, 112, 77, var(--ql-color-blending-lighten3));
    --ql-color-accent1-t-lighten4: rgb(246, 112, 77, var(--ql-color-blending-lighten4));
    --ql-color-accent1-t-lighten5: rgb(246, 112, 77, var(--ql-color-blending-lighten5));
    --ql-color-accent1-t-lighten6: rgb(246, 112, 77, var(--ql-color-blending-lighten6));
    /* Accent —————————————————————————————— Finish */
  
    /* Dark —————————————————————————————— Start */
    --ql-color-dark: rgb(17, 17, 17);
  
    /* Transparent shades */
    --ql-color-dark-t-lighten1: rgb(17, 17, 17, var(--ql-color-blending-lighten1));
    --ql-color-dark-t-lighten2: rgb(17, 17, 17, var(--ql-color-blending-lighten2));
    --ql-color-dark-t-lighten3: rgb(17, 17, 17, var(--ql-color-blending-lighten3));
    --ql-color-dark-t-lighten4: rgb(17, 17, 17, var(--ql-color-blending-lighten4));
    --ql-color-dark-t-lighten5: rgb(17, 17, 17, var(--ql-color-blending-lighten5));
    --ql-color-dark-t-lighten6: rgb(17, 17, 17, var(--ql-color-blending-lighten6));
    /* Dark —————————————————————————————— Finish */
  
    /* Gradient —————————————————————————————— Start */
    --ql-gradient-body: linear-gradient(-45deg, #d0e3f4, #f2edf1);
    /* Gradient —————————————————————————————— Finish */
  
    /* Danger —————————————————————————————— Start */
    --ql-color-danger: rgb(245, 34, 45);
  
    /* Transparent shades */
    --ql-color-danger-t-lighten1: rgb(245, 34, 45, var(--ql-color-blending-lighten1));
    --ql-color-danger-t-lighten2: rgb(245, 34, 45, var(--ql-color-blending-lighten2));
    --ql-color-danger-t-lighten3: rgb(245, 34, 45, var(--ql-color-blending-lighten3));
    --ql-color-danger-t-lighten4: rgb(245, 34, 45, var(--ql-color-blending-lighten4));
    --ql-color-danger-t-lighten5: rgb(245, 34, 45, var(--ql-color-blending-lighten5));
    --ql-color-danger-t-lighten6: rgb(245, 34, 45, var(--ql-color-blending-lighten6));
    /* Danger —————————————————————————————— Finish */
  
    /* Warning —————————————————————————————— Start */
    --ql-color-warning: rgb(250, 173, 20);
  
    /* Transparent shades */
    --ql-color-warning-t-lighten1: rgb(250, 173, 20, var(--ql-color-blending-lighten1));
    --ql-color-warning-t-lighten2: rgb(250, 173, 20, var(--ql-color-blending-lighten2));
    --ql-color-warning-t-lighten3: rgb(250, 173, 20, var(--ql-color-blending-lighten3));
    --ql-color-warning-t-lighten4: rgb(250, 173, 20, var(--ql-color-blending-lighten4));
    --ql-color-warning-t-lighten5: rgb(250, 173, 20, var(--ql-color-blending-lighten5));
    --ql-color-warning-t-lighten6: rgb(250, 173, 20, var(--ql-color-blending-lighten6));
    /* Warning —————————————————————————————— Finish */
  
    /* Layout —————————————————————————————— Start */
    --layout-body-background: var(--ql-gradient-body);
    --ql-layout-padding-default: 16px;
    /* Layout —————————————————————————————— Finish */

    /* Font family —————————————————————————————— Start */ 
    --ql-font-family-main:  "Rubik";
    /* Font family —————————————————————————————— Finish */

    /* Settings —————————————————————————————— Start */
    --ql-typography-heading-color: var(--ql-color-dark);
    --ql-typography-text-color-primary: var(--ql-color-dark);
    --ql-typography-text-color-primary-inverse: var(--ql-color-dark);
    --ql-typography-text-color-secondary: var(--ql-color-dark-t-lighten1);
    /* Settings —————————————————————————————— Finish */
    
    /* Components —————————————————————————————— Start */
    --ql-component-height-md: 46px;
    /* Components —————————————————————————————— Finish */

    /* Alert —————————————————————————————— Start */
    --alert-success-border-color: transparent;
    --alert-success-bg-color: var(--ql-color-success-t-lighten4);
  
    --alert-info-border-color: transparent;
    --alert-info-bg-color: var(--ql-color-accent1-t-lighten4);
  
    --alert-warning-border-color: transparent;
    --alert-warning-bg-color: var(--ql-color-warning-t-lighten4);
  
    --alert-error-border-color: transparent;
    --alert-error-bg-color: var(--ql-color-danger-t-lighten4);
  
    --alert-message-color: var(--ql-typography-text-color-secondary);
    /* Alert —————————————————————————————— Finish */

    /* Avatar —————————————————————————————— Start */
    --ql-avatar-bg: rgb(209, 215, 222);
    --avatar-color: var(--ql-color-dark-t-lighten2);
    --avatar-border-color: var(--ql-avatar-bg);
  
    --avatar-group-overlapping: -16px;
    --avatar-group-border-color: var(--avatar-border-color);
    --avatar-group-border-width: var(--ql-border-width-2);

    --avatar-group-border-color: var(--ql-color-dark-t-lighten6);
    /* Avatar —————————————————————————————— Finish */

    /* Button —————————————————————————————— Start */
    --btn-icon-size: 24px;

    --btn-font-weight: var(--ql-font-weight-semibold);
  
    --btn-default-color: var(--ql-color-dark-t-lighten1);
    --btn-default-bg: var(--ql-color-dark-t-lighten6);
    --btn-default-border: transparent;
  
    --btn-default-hover-border: transparent;
    --btn-default-hover-color: var(--ql-color-dark);
    --btn-default-hover-bg: var(--ql-color-dark-t-lighten5);
  
    --btn-primary-bg: var(--ql-color-dark);
    --btn-primary-border: transparent;

    --btn-primary-hover-bg: var(--ql-color-dark-t-lighten1);
    --btn-primary-hover-border: transparent;
  
    --btn-danger-border: transparent;
  
    --btn-white-hover-color: var(--ql-color-dark);
  
    --btn-text-color: var(--ql-typography-text-color-primary);
  
    --btn-text-hover-color: var(--ql-typography-text-color-primary);
  
    --btn-disable-border: var(--ql-color-dark-t-lighten4);
  
    --btn-group-border: var(--ql-color-accent1);

    --btn-upload-bg: var(--input-bg);
    --btn-upload-border-color: var(--ql-color-dark-t-lighten5);
    /* Button —————————————————————————————— Finish */
     
    /* Card —————————————————————————————— Start */
    --card-background: var(--ql-color-white-t-lighten2);
    /* custom var for card on userShow page */
    --card-user-show-bg: var(--ql-color-white-t-lighten3);
    /* Card —————————————————————————————— Finish */
    
    /* Cascader —————————————————————————————— Start */
    --cascader-item-selected-bg: var(--ql-color-accent1-t-lighten1);
    /* Cascader —————————————————————————————— Finish */
  
    /* Chat —————————————————————————————— Start */
    --chat-bg: var(--ql-color-white-t-lighten4); /* chat view in list of chats */
    --chat-active-bg: var(--ql-color-white-t-lighten3); /* active chat in chats list */
  
    --message-bg: var(--ql-color-white-t-lighten6);
    --message-primary-bg: var(--ql-color-accent1); /* my sent message */
  
    --message-color: var(--ql-color-dark-t-lighten1); /* text color for message */
    --message-primary-color: var(--ql-color-white-t-lighten1); /* text color for my sent message */
  
    --message-info-color: var(--ql-color-dark-t-lighten2); /* text color for time in message*/
    --message-info-primary-color: var(
      --ql-color-white-t-lighten2
    ); /* text color for time and "dots" in message */
    /* Chat —————————————————————————————— Finish */
    
    /* DateLabel —————————————————————————————— Start */
    --date-label-bg: var(--ql-color-white-t-lighten2);
    --date-label-border-radius: var(--ql-border-radius-16);
    --date-label-color: var(--ql-typography-text-color);
    /* DateLabel —————————————————————————————— Finish */

    /* Dropdown —————————————————————————————— Start */
    --dropdown-menu-bg: var(--ql-color-white);
    --dropdown-menu-padding: 4px 0;
  
    --dropdown-vertical-padding: 5px;
  
    --dropdown-font-size: var(--ql-typography-font-size-md);
    --dropdown-line-height: var(--ql-typography-line-height-md);
    
    --dropdown-item-padding: 0px 16px;
  
    --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  
    --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);
  
    --dropdown-menu-submenu-disabled-bg: var(--component-background);
    /* Dropdown —————————————————————————————— Finish */

    /* Input —————————————————————————————— Start */ 
    --input-bg: var(--ql-color-dark-t-lighten5);
    --input-addon-bg: var(--ql-typography-text-color-secondary);
  
    --input-border-color: transparent;
  
    --input-placeholder-color: var(--ql-state-disabled-color);
  
    --input-placeholder-color: var(--ql-color-dark-t-lighten2); 
  
    --input-number-handler-bg: var(--input-bg);
    /* Input —————————————————————————————— Finish */

    /* List —————————————————————————————— Start */ 
    --list-item-border-color: var(--ql-color-dark-t-lighten6);
    --item-active-bg: var(--ql-color-accent1-t-lighten6);
    --item-hover-bg: var(--ql-color-accent1); 
    /* List —————————————————————————————— Finish */

    /* Menu —————————————————————————————— Start */ 
    --menu-item-icon-margin-right: 10px; 
    --menu-item-color-hover: var(--ql-color-accent1);
    --menu-item-active-color: var(--ql-color-dark);
    --menu-item-active-bg: var(--ql-color-accent1-t-lighten4);  
    --menu-horizontal-main-item-color-hover: var(--ql-typography-text-color-primary);
    /* Menu —————————————————————————————— Finish */

    /* Message —————————————————————————————— Start */ 
    --message-notice-content-bg: var(--ql-color-white);
    /* Message —————————————————————————————— Finish */

    /* Pagination —————————————————————————————— Start */ 
    --pagination-font-weight-active: var(--ql-font-weight-medium);
    /* Pagination —————————————————————————————— Finish */

    /* Popover —————————————————————————————— Start */ 
    --popover-bg:  var(--ql-color-white);
    /* Popover —————————————————————————————— Finish */

    /* Rate —————————————————————————————— Start */ 
    --rate-star-color: var(--ql-color-accent1);
    /* Rate —————————————————————————————— Finish */
    
    /* Result —————————————————————————————— Start */ 
    --result-title-font-size: var(--ql-font-size-h3);
    /* Result —————————————————————————————— Finish */

    /* Select —————————————————————————————— Start */
    --ql-select-border-color: var(--input-border-color); 
    --ql-select-item-selected-font-weight: var(--ql-font-weight-medium);
    --ql-select-dropdown-bg: var(--ql-color-white);
    --ql-select-item-hover: var(--ql-color-dark-t-lighten6);
    --ql-select-item-selected-bg:var(--ql-color-accent1-t-lighten4);
    --ql-select-item-active-bg: var(--ql-color-accent1);
    --ql-select-dropdown-vertical-padding: 4px 0;
    --ql-select-dropdown-font-size: var(--ql-typography-font-size-md);
    --ql-select-dropdown-line-height: var(--ql-typography-line-height-md);
    --ql-select-background: var(--ql-color-dark-t-lighten6);
    --ql-select-selection-item-bg: var(--ql-color-accent1);
    /* Select —————————————————————————————— Finish */

    /* Skeleton —————————————————————————————— Start */ 
    --skeleton-color: var(--ql-color-dark-t-lighten3);
    --skeleton-to-color: var(--ql-color-dark-t-lighten1);
    /* Skeleton —————————————————————————————— Finish */

    /* Disabled —————————————————————————————— Start */ 
    --ql-state-disabled-color: var(--ql-color-dark-t-lighten4);
    --ql-state-disabled-bg: var(--ql-color-dark-t-lighten6);
    /* Disabled —————————————————————————————— Finish */

    /* Table —————————————————————————————— Start */ 
    --table-bg: var(--ql-color-white);
    --table-row-hover-bg: var(--ql-color-dark-t-lighten5);
    --table-selected-row-bg: var(--ql-color-accent1-t-lighten5);
    /* Table —————————————————————————————— Finish */

    /* Timeline —————————————————————————————— Start */ 
    --timeline-color: var(--ql-color-dark-t-lighten5);
    --timeline-dot-bg: var(--ql-color-white-t-lighten5);
    /* Timeline —————————————————————————————— Finish */

    /* Tag —————————————————————————————— Start */ 
    --tag-default-bg: var(--ql-color-dark-t-lighten6);
    /* Tag —————————————————————————————— Finish */

    /* Popup —————————————————————————————— Start */ 
    --popup-bg: var(--ql-color-white);
    /* Popup —————————————————————————————— Finish */
    
    /* Typeform —————————————————————————————— Start */ 
    --typeform-content-bg: var(--ql-color-white);
    --typeform-header-mb: 16px;
    /* Typeform —————————————————————————————— Finish */

    /* Grid —————————————————————————————— Start */ 
    --ql-grid-gutter-xs: 8px;
    /* Grid —————————————————————————————— Finish */
}